<template>
    <div class="container">

        <div v-if="deals.length" class="ph-deal-tile-primary ph-text-white p-4 mb-3"
             :style="(deal.promoted) ? 'border: 2px solid ' + deal.colour + '; background-color: ' + deal.colour + '15;' : ''"
             v-for="deal in deals">

            <div class="row">
                <div class="col-12 col-md-6 col-lg-5">
                    <div class="row">
                        <div class="col-12">
                            <span
                                class="d-inline-block py-1 px-3 rounded-pill text-nowrap me-2 mb-2 mb-md-0 bg-primary text-white mt-2"
                                v-if="deal.promotional_text">{{ deal.promotional_text }}</span>
                            <span
                                class="d-inline-block py-1 px-3 rounded-pill text-nowrap me-2 mb-2 mb-md-0 bg-primary text-white mt-2"
                                v-if="deal.gift">{{ deal.gift }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-12 col-lg-6">
                            <div class="ph-deal-tile-primary tile-tariff-block d-flex flex-column px-2 py-3 mb-3 mb-lg-0 text-center">
                                <h4 class="mb-1">{{ deal.initial_cost }}</h4>
                                <p class="" style="font-weight:600;"> total cost</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6  text-center">
                            <a :href=deal.basket_link tabindex="-1"
                                class="d-block ph-btn ph-btn-primary buy-button mb-2" target="_blank"
                                :data-awpid=deal.awpid>Get deal</a>
                            <p class="mb-1" style="color: #777; font-size: 12px;">via</p>
                            <p>{{ deal.merchant }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!deals.length" class="card mt-5 mb-3 border-0">
            <h3 class="text-center">Unfortunately, there are no deals, please try again.</h3>
        </div>
    </div>
</template>

<script>
import {useVariantStore} from '../stores/variant'
import axios from 'axios';

export default {
    data() {
        return {
            name: 'tarifflist',
            deals: [],
        }
    },
    setup() {
        const store = useVariantStore();
        return {store};
    },
    props: ['modelName'],
    computed: {
        changeColour () {
            return this.store.colour;
        },
        changeCapacity () {
            return this.store.capacity;
        },
        changeCondition() {
            return this.store.condition;
        }
    },
    watch: {
        sortBy () {
            this.getDeals();
        },
        changeColour () {
            this.getDeals();
        },
        changeCapacity () {
            this.getDeals();
        },
        changeCondition() {
            this.getDeals();
        },
    },
    methods: {
        getDeals() {
            axios.get('/ajax/simfree/deals/?modelname=' + this.modelName + '&colour=' + this.store.colour + '&capacity=' + this.store.capacity + '&condition=' + this.store.condition)
                .then((response) => {
                    this.deals = response.data.data;
                })
        },
    },
    mounted() {
        console.log("asdasdasd");
       this.getDeals()
    }
}
</script>
