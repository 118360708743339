<template>
    <h2>Become a VIP.</h2>
    <p class="pb-4">We'll slide into your inbox with our very best deals, offers and exciting updates.</p>
    <form @submit="formSubmit" class="">
        <div class="d-flex flex-wrap justify-content-center position-relative w-100">
            <input id="newsletter-email" type="email" class="ph-form-input form-control" v-model="email" required autocomplete="off">
            <label for="newsletter-email" class="ph-form-label">Email address</label>
        </div>
        <button class="ph-btn ph-btn-primary mt-4 w-100" type="submit">Join VIP list</button>
        <p class="mt-2" :class="status ? 'text-white' : 'text-white'">{{output}}</p>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        data () {
            return {
                email: '',
                output: '',
                status: false
            }
        },
        methods: {
            formSubmit(e) {
                e.preventDefault();

                axios.post('/ajax/newsletter-signup', {
                    email: this.email
                })
                .then(response => {
                    this.status = response.data.status;
                    this.output = response.data.message;
                })
                .catch(error => {
                    this.status = false;
                    this.output = "Something went wrong, please try again";
                })
            }
        }
    }
</script>
