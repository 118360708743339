// Import Swiper bundle with all modules
import Swiper from 'swiper/bundle';

// Import Swiper styles
import 'swiper/css/bundle';

// Initialize Swiper - Three Card Carousel
var swiperThreeCard = new Swiper('.ph-swiper-container-three', {
    // Swiper options
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            loop: false,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 50,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: true,
        },
        768: {
            loop: false,
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 25,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: false,
        },
        1200: {
            loop: false,
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 25,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: false,
        },
    }
});
// Initialize Swiper - Three Card Carousel

// Initialize Swiper - Four Card Carousel
var swiperFourCard = new Swiper('.ph-swiper-container-four', {
    // Swiper options
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            loop: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 50,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: true,
        },
        576: {
            loop: false,
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 25,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: false,
        },
        992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 25,
            grabCursor: true,
            allowTouchMove: true,
            centeredSlides: false,
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 25,
            grabCursor: false,
            allowTouchMove: false,
            centeredSlides: false,
        },
    }
});
// Initialize Swiper - Four Card Carousel


/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
let prevScrollpos = window.scrollY;

// Ensure the navbar starts visible
document.getElementById("navbar").style.top = "0";

window.onscroll = function () {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos <= 0) {
        // Prevent hiding the navbar at the top of the page
        document.getElementById("navbar").style.top = "0";
    } else if (prevScrollpos > currentScrollPos) {
        // Scrolling up
        document.getElementById("navbar").style.top = "0";
    } else {
        // Scrolling down
        document.getElementById("navbar").style.top = "-90px";
    }

    prevScrollpos = currentScrollPos;
};